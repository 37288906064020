import { PropsWithChildren } from "react";
import Provider, { createProviderToken, useProvider } from "../../../../shared/provider";
import { useRxStateValue } from "../../../../shared/rxstate";
import { useBrand } from "./BrandProvider";
import { BrandAuthConfig } from "../types/brand";
import { getAuthConfig } from "modules/feature/signin/utils/getConfig";
import ErrorMessage from "modules/error/components/error-message/ErrorMessage";

const providerToken = createProviderToken<BrandAuthConfig>('brandAuthConfig');

export function useBrandAuthConfig(): BrandAuthConfig {
    return useProvider(providerToken);
}

interface Props { }

function BrandAuthConfigProvider(props: PropsWithChildren<Props>) {
    const rxBrand = useBrand();
    const brandId = useRxStateValue(rxBrand.brand);
    const config = brandId ? getAuthConfig(brandId) : null;

    if (config?.error) {
        return <ErrorMessage message="BrandAuthConfigProvider error" />
    }

    return (
      <Provider provide={providerToken} value={config?.data}>
          {props.children}
      </Provider>
    )
}

export default BrandAuthConfigProvider
