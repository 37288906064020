import { ElementCompact, js2xml, xml2js } from "xml-js";

import { JsonParser } from '../../../../../shared/types/parser';
import { GetterFn } from "../../../../../shared/types/common";
import { XmlParser } from "../../../../../shared/types/parser";

export interface RingcentralContactTaskConfig {
    sourceSystem: string;
    accessToken: string;
    accountId: string;
    authorityUri: string;
    clientId: string;
    clientSecret: string;
    deletePreviousImport: boolean;
    expiresAt: string;
    pageSize: number;
    redirectUri: string;
    refreshToken: string;
    refreshTokenExpiresAt: string;
    typeFilter: string[];
    useJwtAuth: boolean;
    jwtAuthToken: string;
}

export interface RingcentralContactTaskConfigParser extends XmlParser<RingcentralContactTaskConfig>, JsonParser<RingcentralContactTaskConfig> {
    empty: GetterFn<RingcentralContactTaskConfig>;
}

function _toJson(config: RingcentralContactTaskConfig): Record<string, any> {
    return {
        "sourceSystem": config.sourceSystem,
        "accessToken": config.accessToken,
        "accountId": config.accountId,
        "authorityUri": config.authorityUri,
        "clientId": config.clientId,
        "clientSecret": config.clientSecret,
        "deletePreviousImport": config.deletePreviousImport,
        "expiresAt": config.expiresAt,
        "pageSize": config.pageSize,
        "redirectUri": config.redirectUri,
        "refreshToken": config.refreshToken,
        "refreshTokenExpiresAt": config.refreshTokenExpiresAt,
        "typeFilter": config.typeFilter,
        "useJwtAuth": config.useJwtAuth,
        "jwtAuthToken": config.jwtAuthToken,
    }
}

function _fromJson(json: Record<string, any>): RingcentralContactTaskConfig {
    return {
        sourceSystem: json['sourceSystem'],
        accessToken: json['accessToken'],
        accountId: json['accountId'],
        authorityUri: json['authorityUri'],
        clientId: json['clientId'],
        clientSecret: json['clientSecret'],
        deletePreviousImport: json['deletePreviousImport'],
        expiresAt: json['expiresAt'],
        pageSize: json['pageSize'],
        redirectUri: json['redirectUri'],
        refreshToken: json['refreshToken'],
        refreshTokenExpiresAt: json['refreshTokenExpiresAt'],
        typeFilter: json['typeFilter'],
        useJwtAuth: json['useJwtAuth'],
        jwtAuthToken: json['jwtAuthToken'],
    }
}

function _empty(): RingcentralContactTaskConfig {
    return {
        sourceSystem: '',
        accessToken: '',
        accountId: '',
        authorityUri: '',
        clientId: '',
        clientSecret: '',
        deletePreviousImport: false,
        useJwtAuth: false,
        expiresAt: '',
        pageSize: 0,
        redirectUri: '',
        refreshToken: '',
        refreshTokenExpiresAt: '',
        typeFilter: [],
        jwtAuthToken: ''
    }
}

function _fromXml(xml: string): RingcentralContactTaskConfig {
    const obj: ElementCompact = xml2js(xml, { compact: true });
    if (obj.RingCentralContactConfig) {
        const config = obj.RingCentralContactConfig
        return {
            sourceSystem: config.SourceSystem?._text ?? '',
            accessToken: config.AccessToken?._text ?? '',
            accountId: config.AccountId?._text ?? '',
            authorityUri: config.AuthorityUri?._text ?? '',
            clientId: config.ClientId?._text ?? '',
            clientSecret: config.ClientSecret?._text ?? '',
            deletePreviousImport: config.DeletePreviousImport?._text === 'true',
            useJwtAuth: config.UseJwtAuth?._text === 'true',
            expiresAt: config.ExpiresAtUtc?._text ?? '',
            pageSize: config.PageSize?._text ?? '0',
            redirectUri: config.RedirectUri?._text ?? '',
            refreshToken: config.RefreshToken?._text ?? '',
            jwtAuthToken: config.JwtAuthToken?._text ?? '',
            refreshTokenExpiresAt: config.RefreshTokenExpiresAtUtc?._text ?? '',
            typeFilter: config.TypeFilter?._text?.split(/\s/) ?? []
        }
    }
    return _empty();
}

function _toXml(config: RingcentralContactTaskConfig): string {
    return js2xml({
        RingCentralContactConfig: {
            _attributes: {
                "xmlns:i": "http://www.w3.org/2001/XMLSchema-instance",
                "xmlns": "http://schemas.datacontract.org/2004/07/Pridis.ConnecsyEnterprise.Imports.RingCentralContactImport.ImportParser",
            },
            SourceSystem: {
                _text: config.sourceSystem,
                _attributes: {
                    "xmlns": "http://schemas.datacontract.org/2004/07/Pridis.ConnecsyEnterprise.AttendantSharedLib.Scheduler"
                }
            },
            AccessToken: !!config.accessToken ? { _text: config.accessToken } : { _attributes: { "i:nil": true } },
            AccountId: { _text: config.accountId },
            AuthorityUri: { _text: config.authorityUri },
            ClientId: { _text: config.clientId },
            ClientSecret: { _text: config.clientSecret },
            DeletePreviousImport: { _text: config.deletePreviousImport },
            ExpiresAtUtc: !!config.expiresAt ? { _text: config.expiresAt } : { _attributes: { "i:nil": true } },
            PageSize: { _text: config.pageSize },
            RedirectUri: { _text: config.redirectUri },
            RefreshToken: !!config.refreshToken ? { _text: config.refreshToken } : { _attributes: { "i:nil": true } },
            RefreshTokenExpiresAtUtc: !!config.refreshTokenExpiresAt ? { _text: config.refreshTokenExpiresAt } : { _attributes: { "i:nil": true } },
            TypeFilter: { _text: config.typeFilter.join(' ') },
            JwtAuthToken: (!!config.jwtAuthToken && config.useJwtAuth) ? { _text: config.jwtAuthToken } : { _attributes: { "i:nil": true } },
            UseJwtAuth: { _text: config.useJwtAuth },
        }
    }, { compact: true })
}

let _parser: RingcentralContactTaskConfigParser;

export function getRingcentralContactConfigParser(): RingcentralContactTaskConfigParser {
    if (!_parser) {
        _parser = {
            empty: _empty,
            toXml: _toXml,
            fromXml: _fromXml,
            toJson: _toJson,
            fromJson: _fromJson,
        }
    }
    return _parser;
}