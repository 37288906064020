import { FromJsonParser, ToJsonParser } from './../../../../shared/types/parser';
import dayjs from "dayjs";
import { XmlParser } from "../../../../shared/types/parser";
import { isEmptyString, isNotEmptyString, isNullOrUndefined } from "../../../../shared/validators/common";
import { Task } from "./task";
import { timeFormats } from '../../../../shared/utils/date';

export interface TaskFormData<T> {
    id: string;
    name: string;
    taskType: string;
    rescheduleOnError: boolean;
    starts: Date | undefined;
    startsAt: string;
    intervalDays: number;
    intervalHours: number;
    intervalMinutes: number;
    taskConfig: T;
}

export interface TaskFormDataParser {
    fromTask: <T>(task: Task, parser: XmlParser<T>) => TaskFormData<T>;
    fromJson: <T>(json: Record<string, any>, configParser: FromJsonParser<T>) => TaskFormData<T>;
    toJson: <T>(config: TaskFormData<T>, configParser: ToJsonParser<T>) => Record<string, any>;
    toRequestData: <T>(data: TaskFormData<T>, parser: XmlParser<T>) => Record<string, any>;
}

function _fromJson<T>(json: Record<string, any>, parser: FromJsonParser<T>): TaskFormData<T> {
    return {
        id: json['id'],
        name: json['name'],
        taskType: json['taskType'],
        rescheduleOnError: json['rescheduleOnError'],
        starts: json['starts'] ? dayjs(json['starts']).toDate() : undefined,
        startsAt: json['startsAt'],
        intervalDays: json['intervalDays'],
        intervalHours: json['intervalHours'],
        intervalMinutes: json['intervalMinutes'],
        taskConfig: parser.fromJson(json['taskConfig'])
    }
}

function _toJson<T>(config: TaskFormData<T>, parser: ToJsonParser<T>): Record<string, any> {
    return {
        "id": config.id,
        "name": config.name,
        "taskType": config.taskType,
        "rescheduleOnError": config.rescheduleOnError,
        "starts": config.starts?.toISOString(),
        "startsAt": config.startsAt,
        "intervalDays": config.intervalDays,
        "intervalHours": config.intervalHours,
        "intervalMinutes": config.intervalMinutes,
        "taskConfig": parser.toJson(config.taskConfig),
    }
}


function _fromTask<T>(task: Task, parser: XmlParser<T>): TaskFormData<T> {
    let _interval = isNotEmptyString(task.interval) ? task.interval! : '0.00:00:00';
    if (_interval.indexOf(".") === -1) _interval = `0.${_interval}`;
    const _splitedInterval = _interval.split(".");
    const _days = +_splitedInterval[0];
    const _splittedTime = _splitedInterval[1].split(":");
    const _hours = _splittedTime[0];
    const _minutes = _splittedTime[1];

    const startDate = !!task.starts ? dayjs(task.starts) : undefined;
    const startsAt = !!task.startsAt
        ? dayjs(task.startsAt, timeFormats).toDate().toLocaleTimeString()
        : !!startDate && (startDate.hour() > 0 || startDate.minute() > 0)
            ? startDate.toDate().toLocaleTimeString()
            : '';

    return {
        id: task.id,
        name: task.name,
        taskType: task.taskType,
        rescheduleOnError: task.rescheduleOnError,
        starts: task.starts,
        startsAt: startsAt,
        intervalDays: +_days,
        intervalHours: +_hours,
        intervalMinutes: +_minutes,
        taskConfig: parser.fromXml(task.taskConfig)
    }
}

function _toRequestData<T>(data: TaskFormData<T>, parser: XmlParser<T>): Record<string, any> {
    let _starts = data.starts;
    let _startsAt = data.startsAt;

    if (!isNullOrUndefined(_starts) && !isEmptyString(_startsAt)) {
        const time = dayjs(_startsAt, timeFormats);
        _starts?.setHours(time.hour());
        _starts?.setMinutes(time.minute());
        _startsAt = '';
    }

    const _days = isEmptyString(`${data.intervalDays}`) ? 0 : +data.intervalDays;
    const _hours = isEmptyString(`${data.intervalHours}`) ? 0 : +data.intervalHours;
    const _minutes = isEmptyString(`${data.intervalMinutes}`) ? 0 : +data.intervalMinutes;
    let _interval: any = `${_days}.${_hours}:${_minutes}:00`;
    if (_days === 0 && _hours === 0 && _minutes === 0) {
        _interval = null;
    }

    return {
        "id": data.id,
        "interval": _interval,
        "name": data.name,
        "rescheduleOnError": data.rescheduleOnError,
        "starts": _starts ? dayjs(_starts).toISOString() : null,
        "startsAt": isEmptyString(_startsAt) ? null : dayjs(_startsAt, timeFormats).format("HH:mm:ss"),
        "taskConfig": parser.toXml(data.taskConfig),
        "taskType": data.taskType,
    }
}

export const taskFormDataParser: TaskFormDataParser = {
    fromTask: _fromTask,
    fromJson: _fromJson,
    toJson: _toJson,
    toRequestData: _toRequestData,
}