import classNames from "classnames";
import React from "react";

import styles from "./Logo.module.css";

interface Props {
  width: string;
  height: string;
}

function ConnecsyLogo(props: Props) {

  return (
    <div className={classNames(styles.logo_container)}>
      <img
        src={"/assets/images/logo.svg"}
        alt={"ConnecsyCloud"}
        width={props.width}
        height={props.height}
        style={{
          width: props.width,
          height: props.height,
        }}
      />

      <div>
        <span className={classNames(styles.app_name_text)}>Connecsy | Cloud</span>
      </div>
    </div>
  );
}

export default ConnecsyLogo;
