import { OidcAuthConfig } from "modules/core/auth/types/oidc-auth-config";
import { AvailableBrands, BrandAuthConfig } from "modules/core/configuration/types/brand"

type ConfigReturn<T> = {
    data: T,
    error: boolean
}

export const ConfigWildcard = {
    AB: '{"ab":[2100, 2000, 3010, 5050, 2101, 5051, 2020]}',
}

// Brand auth config. Wildrcards are replaced during deployment
export const brandAC = {
    clientType: +resolveConfigValue(
        '3',
        process.env.REACT_APP_CLIENT_TYPE
      ),
    clientVersion: +resolveConfigValue(
        '1',
        process.env.REACT_APP_CLIENT_VERSION
      ),
    apiVersion: +resolveConfigValue(
        '1',
        process.env.REACT_APP_API_VERSION
      ),
    clb: resolveConfigValue(
        'https://testadmin.pridis.com/auth/callback', //callback uri
        process.env.REACT_APP_CLB
      ),
    dbu: resolveConfigValue(
        'https://connecsy-auth.pridis.com:5000', //discovery base uri
        process.env.REACT_APP_DBU
      ),
    aid: resolveConfigValue(
        'a52d76e0-8892-4dfc-af0b-775079ce4051', //app id
        process.env.REACT_APP_AID
      ),
    art: resolveConfigValue(
        '3d0b2a38-0409-49b9-b683-26381d10b6f5', //app secret
        process.env.REACT_APP_ART
      ),
}

// Google auth config. Wildrcards are replaced during deployment
export const gac = {
    aur: resolveConfigValue(
        'https://accounts.google.com', //authority uri
        process.env.REACT_APP_G_AUR
      ),
    rur: resolveConfigValue(
        'https://connecsy-admin-prod.azurewebsites.net/auth/google/callback', //redirect uri
        process.env.REACT_APP_G_RUR
      ),
    sp: resolveConfigValue(
        'openid https://www.googleapis.com/auth/userinfo.profile', //scope
        process.env.REACT_APP_G_SP
      ),
    cid: resolveConfigValue(
        '252690431695-kjbdh7kmt4avnnvoc8k0ar0hlp5h6258.apps.googleusercontent.com', //client id
        process.env.REACT_APP_G_CID
      ),
    crt: resolveConfigValue(
        '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', //client secret
        process.env.REACT_APP_G_CRT
      ),
}

export const getAuthConfig = (brandId: string): ConfigReturn<BrandAuthConfig | null>=> {
    const { data: bId, error: brandCheckError } = checkIsBrandAvailable(brandId);
    const ret: ConfigReturn<BrandAuthConfig | null> = { data: null, error: false };

    if (brandCheckError) {
        ret.error = true;

        return ret;
    }

    if (!bId) {
        return ret
    }

    const config = {
        ...brandAC,
        // name: brandInfo.n,
        bid: bId
    }

    ret.data = config;

    return ret;
}

export const getGoogleAuthConfig = (): ConfigReturn<OidcAuthConfig | null> => {
    return {data: gac, error: false}
}

export function checkIsBrandAvailable (brandId: string | number): ConfigReturn<string | null> {
    const bId = brandId.toString();
    const ret: ConfigReturn<string | null> = {
        data: null,
        error: false
    }

    const { data, error } = getConfig(ConfigWildcard.AB, process.env.REACT_APP_AB) as ConfigReturn<AvailableBrands | null>;

    if (error || !data) {
        ret.error = true;

        return ret;
    }

    // !!!Attention: do not use strict comparison of brand ids in the find() below!!!
    // eslint-disable-next-line eqeqeq
    ret.data = data.ab.includes(+bId) ? bId : null;

    return ret;
}

function getConfig(buildVal = '', envVal = ''): ConfigReturn<unknown | null> {
    const stringConfig = resolveConfigValue(buildVal, envVal)
    const ret = {
        data: null,
        error: false
    }

    try {
        const config = stringConfig ? JSON.parse(stringConfig.trim()) : null;
        ret.data = config;

        return ret;
    } catch (err) {
        ret.error = true;

        return ret;
    }
}

export function resolveConfigValue(buildVal = '', envVal = ''): string {
    // The condition below is used for clients training environment that consumes env vars directly from azure devops lib
    if (process.env.REACT_APP_USE_ENV_VAR === "true") {
      return envVal;
    }

    return process.env.NODE_ENV === 'production' ? buildVal : envVal;
  }

